<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <modal :adaptive="false" id="open_to_add_collection_name" name="open_to_add_collection_name" style="z-index: 999999 ">
      <AddCollectionModal ref="addCollectionModal" @onSubmit="addCollectionModalSubmit">
      </AddCollectionModal>
    </modal>

    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="section-header pb-0 mt-2 uk-width-4-4@m">
          <div class="section-header-left">
            <h3 class="uk-text-truncate"><i class="uil-notebooks"></i> {{ $t('home.My_collections') }}</h3>
          </div>
          <div class="section-header-right">
            <a class="uk-width-2-1 text-white btn btn-primary btn-sm transition-3d-hover" @click="addCollection">
              <i class="uil-plus"></i>
              {{ $t("collection.add_collection") }}
            </a>
          </div>
        </div>
        <div class="section-header pb-0">
          <div class="section-header-left">
            <input class="mt-3" type="text" @input="isTyping = true" v-model="search"
              :placeholder="$t('home.Education_shearing')">
          </div>
          <div class="section-header-right">
            <select class=" ml-3 mb-0" v-model="order_by" @change="applyFilter">
              <option value="name">{{ $t('home.Filter_by_name') }}</option>
              <option value="created_at">{{ $t('home.Filter_by_date') }}</option>
            </select>
          </div>
        </div>

        <div v-if="successMessage != null" class="uk-alert-success position-relative" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p class="m-0" style="color: green">
            <i aria-hidden="true" class="fa fa-check d-inline-flex mr-2"></i>{{ successMessage }}
          </p>
        </div>
        <div v-if="errorMessage != null" class="uk-alert-danger bg-soft-danger position_relative" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p class="m-0" style="color: red">{{ errorMessage }}</p>
        </div>

        <!-- LOADING BOX -->
        <default-loading v-if="collections_loading"></default-loading>

        <!-- CONTENT -->
        <template v-else>
          <div>
            <nav class="responsive-tab style-2">
              <ul>
                <li><a href="javascript:void(0)" :class="{ active: currentTab === 'my_collections' }"
                    @click="currentTab = 'my_collections'">{{ $t('collection.my_collections') }}</a></li>
                <li><a href="javascript:void(0)" :class="{ active: currentTab === 'following_collections' }"
                    @click="currentTab = 'following_collections'">{{ $t('collection.following_collections') }}</a></li>
                <li><a href="javascript:void(0)" :class="{ active: currentTab === 'all_collections' }"
                    @click="currentTab = 'all_collections'">{{ $t('collection.all_collections') }}</a></li>
              </ul>
            </nav>
          </div>

          <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match mt-5" uk-grid>
            <template v-for="collection in my_collections" v-if="currentTab === 'my_collections'">
              <collection @onClickCard="collectionDetails" :id="collection.id" :title="collection.name"
                :image="collection.cover_image_link" :date="collection.created_at" :counter="collection.collection_contents_count + collection.collection_training_contents_count +
                  collection.external_contents_count"></collection>
            </template>

            <template v-for="collection in following_collections" v-if="currentTab === 'following_collections'">
              <collection @onClickCard="collectionDetails" :id="collection.id" :title="collection.name"
                :image="collection.cover_image_link" :date="collection.created_at" :counter="collection.collection_contents_count + collection.collection_training_contents_count +
                  collection.external_contents_count"></collection>
            </template>

            <template v-for="collection in all_collections" v-if="currentTab === 'all_collections'">
              <collection @onClickCard="collectionDetails" :id="collection.id" :title="collection.name"
                :image="collection.cover_image_link" :date="collection.created_at" :counter="collection.collection_contents_count + collection.collection_training_contents_count +
                  collection.external_contents_count"></collection>
            </template>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import Collection from "@/view/components/index/Collection";
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { mapGetters } from "vuex";
import AddCollectionModal from "@/view/components/educationDetail/AddCollectionModal";

export default {
  name: "MyCollection",
  components: {
    Collection,
    DefaultLoading,
    AddCollectionModal
  },
  computed: {
    ...mapGetters({
      collections_loading: LOADING
    }),
  },
  data() {
    return {
      collectionUrl: 'api/collection',
      collectionAllUrl: 'api/collection-all',
      my_collections: [],
      following_collections: [],
      all_collections: [],
      search: '',
      order_by: 'name',
      sort: 'desc',
      isTyping: false,
      searchResult: [],
      currentTab: 'my_collections',
      successMessage: null,
      errorMessage: null,
    };
  },
  methods: {
    addCollection() {
      this.$modal.show("open_to_add_collection_name");
    },
    addCollectionModalSubmit(payload) {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      this.$refs["addCollectionModal"].closeModal();
      if (payload.success) {
        self.successMessage = self.$t("collection.collection_saved_success");
        let temp = [...self.my_collections];
        payload.data.collection_contents_count = 0;
        payload.data.collection_training_contents_count = 0;
        temp.push(payload.data);
        self.my_collections = [...temp];
      } else {
        self.errorMessage = payload.message;
      }
    },
    collectionDetails(payload) {
      this.$router.push({ name: 'user.collection-detail', params: { id: payload } })
    },

    getFilteredAllCollections(search = null) {
      let self = this;
      let sort = null
      if (this.order_by == "name") {
        sort = "asc"
      }
      this.$store.dispatch(GET_ITEMS, {
        url: this.collectionAllUrl,
        filters: {
          search: search,
          order_by: this.order_by,
          sort: sort
        },
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        self.all_collections = result.data;
      });
    },
    getFilteredCollections(search = null) {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.collectionUrl,
        filters: {
          search: search,
          order_by: this.order_by,
        },
        acceptPromise: false,
        showLoading: true,
      }).then(result => {
        self.my_collections = result.data.my_collections;
        self.following_collections = result.data.following_collections;
      });
    },
    applyFilter: function (search = null) {
      this.getFilteredCollections(search);
      this.getFilteredAllCollections(search);
    },
  },
  mounted() {
    this.applyFilter();
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 500),
    isTyping: function (value) {
      if (!value) {
        this.applyFilter(this.search);
      }
    }
  }
}
</script>

<style scoped>
.responsive-tab li a.active {
  color: #3e416d;
  opacity: 0.8;
}

::v-deep #open_to_add_collection_name .vm--modal {
  height:480px !important;
}
</style>
